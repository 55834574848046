import { FullPageLoad, useAxios } from "common";
import React from "react";
import Dashboard from "./Dashboard";

const PoDashboard = () => {
  const { response } = useAxios({
    callOnLoad: { method: "GET", url: "/poDashboard" },
  });

  if (!response) return <FullPageLoad fillWidth />;

  return <Dashboard data={response.data} />;
};

export default PoDashboard;
