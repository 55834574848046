import {
  Button,
  Dropdown,
  formatNumber,
  OverflowContainer,
  RowFlex,
  sortAndFormatOptions,
  Table,
  theme,
} from "common";
import DateRangePicker from "common/components/DateRangePicker";
import { isAfter, isBefore } from "date-fns";
import { saveAs } from "file-saver";
import { orderBy } from "lodash";
import { uniq } from "ramda";
import { useEffect, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Ban, BanContainer } from "../styles";
import {
  PoDashCharts,
  PoDashContainer,
  StyledSlider,
  Thumb,
  Track,
} from "./styles";

const Dashboard = ({ data = [] }) => {
  const [filteredData, setFilteredData] = useState(data);
  const amounts = data.map((d) => d.Amount);
  const maxAmount = Math.max(...amounts);
  const minAmount = Math.min(...amounts);
  const [brand, setBrand] = useState("");
  const [dateRange, setDateRange] = useState();
  const [orderValue, setOrderValue] = useState([minAmount, maxAmount]);

  const [brands] = useMemo(() => {
    const [b] = [[]];
    let a = 0;
    for (const d of data) {
      b.push(d.Brands);
      a += d.Amount;
    }

    return [...[b].map((a) => uniq(a).filter(Boolean)), a];
  }, [data]);

  const [filteredBrands, filteredPos, filteredAmount] = useMemo(() => {
    const [b, p] = [[], [], []];
    let a = 0;
    for (const d of filteredData) {
      b.push(d.Brands);
      p.push(d.PONumber);
      a += d.Amount;
    }

    return [...[b, p].map((a) => uniq(a).filter(Boolean)), a];
  }, [filteredData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilteredData(
        data.filter((d) => {
          const brandFilter = !brand || d.Brands === brand;
          const date = new Date(d.PODate);
          const dateRangeFilter =
            !dateRange ||
            (isBefore(date, new Date(dateRange.$lte)) &&
              isAfter(date, new Date(dateRange.$gte)));
          const amountFilter =
            d.Amount <= orderValue[1] && d.Amount >= orderValue[0];

          return brandFilter && dateRangeFilter && amountFilter;
        })
      );
    }, 500);
    return () => clearTimeout(timeout);
  }, [data, brand, dateRange, orderValue]);

  const allBrandsData = useMemo(() => {
    const tbMap = filteredData.reduce((acc, d) => {
      if (!acc[d.Brands])
        acc[d.Brands] = {
          name: d.Brands,
          amount: 0,
          count: 0,
          vendor: d.VendorName,
        };
      acc[d.Brands].amount += d.Amount ?? 0;
      acc[d.Brands].count++;
      return acc;
    }, {});
    return orderBy(Object.values(tbMap), "count", "desc");
  }, [filteredData]);

  const topBrandsData = allBrandsData.slice(0, 50);

  return (
    <PoDashContainer>
      <RowFlex responsive extend>
        <RowFlex responsive>
          <h2>PO Dashboard</h2>
          <Dropdown
            width="200px"
            isClearable
            value={brand}
            options={sortAndFormatOptions({
              list: brands,
              labelKey: (b) => b,
              valueKey: (v) => v,
            })}
            label="Brand"
            onChange={(e) => setBrand(e)}
          />
          <DateRangePicker
            width="200px"
            value={dateRange}
            onChange={(v) => setDateRange(v)}
            label="PO Date"
          />
          <StyledSlider
            max={maxAmount}
            min={minAmount}
            onChange={(e) => {
              setOrderValue(e);
            }}
            value={orderValue}
            renderTrack={Track}
            renderThumb={Thumb}
          />
        </RowFlex>
        <Button
          fit
          onClick={() => {
            const str = [
              [
                "Brand",
                "PO Number",
                "PO Date",
                "Amount",
                "Status",
                "Vendor",
                "Ordered",
              ].join(", "),
              ...data.map((d) =>
                [
                  d.Brands.replace(/,/g, ""),
                  d.PONumber,
                  d.PODate,
                  d.Amount,
                  d.Status,
                  d.VendorName.replace(/,/g, ""),
                  d.OrderedQty,
                ].join(", ")
              ),
            ].join("\n");
            const blob = new Blob([str], { type: "text/plain;charset=utf-8" });
            saveAs(blob, "Purchase Orders.csv");
          }}
        >
          Download POs
        </Button>
      </RowFlex>
      <BanContainer count={3}>
        <Ban kpi={filteredBrands.length} title="Brands" />
        <Ban kpi={filteredPos.length} title="POs" />
        <Ban
          kpi={Math.round(filteredAmount / 1000)}
          suffix="K"
          prefix="$"
          title="Amount"
        />
      </BanContainer>
      <PoDashCharts>
        <h3>Top 10 Brands By PO</h3>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={topBrandsData}>
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" />
            <Tooltip formatter={(value) => formatNumber(value)} />
            <Bar
              yAxisId="left"
              dataKey="count"
              fill={theme.colors.primary}
              onClick={(d) => {
                setBrand(d.name);
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </PoDashCharts>
      <OverflowContainer>
        <Table>
          <thead>
            <tr>
              <th>Brand</th>
              <th>Vendor</th>
              <th>POs</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {allBrandsData.map((b) => (
              <tr key={b.name}>
                <td>{b.name}</td>
                <td>{b.vendor}</td>
                <td>{b.count}</td>
                <td>${formatNumber(b.amount)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </OverflowContainer>
    </PoDashContainer>
  );
};

export default Dashboard;
