import axios from "axios";
import {
  Button,
  linkPlaceholders,
  routing,
  Tab,
  TabContainer,
  TabDisplay,
  TableView,
  Tabs,
  TopBar,
  useAxios,
  useVendorRequestsStatusChanges,
  useVendorRequestsStatusChangesNDaysNoChange,
} from "common";
import { format } from "date-fns";
import { useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { baseURL } from "../../common/axios.js";
import { Container } from "./styles";
const DASHBOARD = "DASHBOARD";
const thirtyDaysNoChange = "thirtyDaysNoChange";
const sixtyDaysNoChange = "sixtyDaysNoChange";

const Reports = () => {
  const [refetch, setRefetch] = useState(true);
  const [p] = useSearchParams();
  const selectedTab = p.get("tab");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { statusChanges, refetch: refetchStatusChanges } =
    useVendorRequestsStatusChanges();
  const { records: thirtyDaysNoChangeRecords } =
    useVendorRequestsStatusChangesNDaysNoChange(30);
  const { records: sixtyDaysNoChangeRecords } =
    useVendorRequestsStatusChangesNDaysNoChange(60);
  const { callAxios } = useAxios();

  const isDashboard = selectedTab === DASHBOARD;
  const isThirtyDaysNoChange = selectedTab === thirtyDaysNoChange;
  const isSixtyDaysNoChange = selectedTab === sixtyDaysNoChange;

  const navigateTo = (tab = DASHBOARD) => {
    navigate({ pathname, search: `?${createSearchParams({ tab })}` });
  };

  if (!selectedTab) {
    navigateTo();
  }
  const downloadExportDays = (days) => {
    const exportUrl = `${baseURL}/vendorRequests/all/statusChanges/nDaysNoChange/${days}/export`;

    const a = document.createElement("a");
    a.target = "_blank";
    a.href = exportUrl;
    a.click();
    a.remove();
  };

  function handleButtonClick(brandRequestIds, dismissalDays) {
    const dismissPromises = brandRequestIds.map((id) => {
      const url = `${baseURL}/dismissVendor/${id}?dismissalDays=${dismissalDays}`;
      console.log(`Calling API for vendor ID: ${id} with URL: ${url}`);
      return axios.put(url);
    });

    Promise.all(dismissPromises)
      .then((responses) => {
        console.log("All dismissals successful:", responses);
        setRefetch((prevState) => !prevState);
      })
      .catch((error) => {
        console.error("Error dismissing the vendor request(s):", error.message);
      });
  }

  const renderTable = () => {
    if (isDashboard) {
      return (
        <TableView
          url="/vendorRequests/all/statusChanges"
          selectedStatus={selectedTab}
          defaultFilters={{ selectedTab }}
          to={routing.brandRequests.view}
          linkParam={linkPlaceholders.brandRequestId}
          tableConfig={[
            {
              name: "description",
              header: "Description",
              center: true,
            },
          ]}
          header="Dashboard"
          darker
          actionLinks={[
            {
              name: "Mark As Reviewed",
              action: (e, d, reloadTable) => {
                e.preventDefault();
                callAxios({
                  method: "PUT",
                  url: `/vendorRequests/${d._id}/statusChanges/${d.statusChanges._id}`,
                  data: {
                    isReviewed: true,
                  },
                }).then(() => {
                  reloadTable();
                  refetchStatusChanges();
                });
              },
            },
          ]}
          height="55vh"
          shapeData={(res) =>
            res.data.data.map((d) => ({
              ...d,

              id: d?.brandRequest?._id,
              description: (
                <div>
                  On{" "}
                  <b>
                    {format(
                      new Date(d.statusChanges?.timestamp),
                      "hh:mm a MMM dd, yyyy"
                    )}
                  </b>
                  , brand: <b>{d?.brandRequest?.brandName}</b> was changed from{" "}
                  <b>{d.statusChanges.previousStatus}</b> to{" "}
                  <b>{d.statusChanges.status}</b>.
                </div>
              ),
            }))
          }
        />
      );
    }

    if (isThirtyDaysNoChange) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <h2 style={{ fontSize: "35px" }}>Thirty Days No Change</h2>
            <Button
              style={{
                fontSize: "12px",
                width: "150px",
              }}
              onClick={() => downloadExportDays(30)}
            >
              Export
            </Button>
          </div>
          <TableView
            url="/vendorRequests/all/statusChanges/nDaysNoChange/30"
            selectedStatus={selectedTab}
            defaultFilters={{ selectedTab }}
            to={routing.brandRequests.view}
            linkParam={linkPlaceholders.brandRequestId}
            tableConfig={[
              {
                name: "brandName_v2",
                header: "Brand Name",
              },
              {
                name: "vendorName_v2",
                header: "Vendor Name",
              },
              {
                name: "category_v2",
                header: "Category",
              },
              {
                name: "status_v2",
                header: "Status",
              },
              {
                name: "dismiss",
                sortable: false,
                noTo: true,
                renderCell: (value) => {
                  const brandRequestIds = Array.isArray(value.brandRequestIds)
                    ? value.brandRequestIds
                    : [value.brandRequestIds];
                  return (
                    <td>
                      <Button
                        style={{ fontSize: "12px", width: "110px" }}
                        onClick={() => handleButtonClick(brandRequestIds, 30)}
                      >
                        Dismiss
                      </Button>
                    </td>
                  );
                },
              },
            ]}
            header=""
            darker
            height="55vh"
            shapeData={(res) => {
              const groupedByBrandName = new Map();

              res.data.data.forEach((d) => {
                const brandName = d?.brandRequest?.brandName;
                const brandRequestIds = d?.brandRequest?._id;

                const entry = {
                  id: d?.brandRequest?._id,
                  brandRequestIds: [brandRequestIds],
                  brandName_v2: brandName,
                  vendorName_v2: d?.vendorRequest?.name,
                  category_v2: d?.brandRequest?.category?.name,
                  status_v2: d?.status,
                };

                if (groupedByBrandName.has(brandName)) {
                  const existingEntry = groupedByBrandName.get(brandName);
                  existingEntry.vendorName_v2 += `, ${d?.vendorRequest?.name}`;
                  existingEntry.brandRequestIds.push(brandRequestIds);
                  groupedByBrandName.set(brandName, existingEntry);
                } else {
                  groupedByBrandName.set(brandName, entry);
                }
              });

              const shapedData = [];
              groupedByBrandName.forEach((entry) => {
                shapedData.push(entry);
              });

              return shapedData;
            }}
            refetch={refetch}
          />
        </>
      );
    }
    if (isSixtyDaysNoChange) {
      return (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <h2 style={{ fontSize: "35px" }}>Sixty Days No Change</h2>
            <Button
              style={{
                fontSize: "12px",
                width: "150px",
              }}
              onClick={() => downloadExportDays(60)}
            >
              Export
            </Button>
          </div>
          <TableView
            url="/vendorRequests/all/statusChanges/nDaysNoChange/60"
            selectedStatus={selectedTab}
            defaultFilters={{ selectedTab }}
            to={routing.brandRequests.view}
            linkParam={linkPlaceholders.brandRequestId}
            tableConfig={[
              {
                name: "brandName_v3",
                header: "Brand Name",
              },
              {
                name: "vendorName_v3",
                header: "Vendor Name",
              },
              {
                name: "category_v3",
                header: "Category",
              },
              {
                name: "status_v3",
                header: "Status",
              },
              {
                name: "dismiss",
                sortable: false,
                noTo: true,
                renderCell: (value) => {
                  const brandRequestIds = Array.isArray(value.brandRequestIds)
                    ? value.brandRequestIds
                    : [value.brandRequestIds];

                  return (
                    <td>
                      <Button
                        style={{ fontSize: "12px", width: "110px" }}
                        onClick={() => handleButtonClick(brandRequestIds, 60)}
                      >
                        Dismiss
                      </Button>
                    </td>
                  );
                },
              },
            ]}
            header=""
            darker
            height="55vh"
            shapeData={(res) => {
              const groupedByBrandName = new Map();

              res.data.data.forEach((d) => {
                const brandName = d?.brandRequest?.brandName;
                const brandRequestIds = d?.brandRequest?._id;

                const entry = {
                  id: d?.brandRequest?._id,
                  brandRequestIds: [brandRequestIds],
                  vendorName_v3: d?.vendorRequest.name,
                  category_v3: d?.brandRequest?.category?.name,
                  status_v3: d?.status,
                  brandName_v3: brandName,
                };

                if (groupedByBrandName.has(brandName)) {
                  const existingEntries = groupedByBrandName.get(brandName);
                  const firstEntry = existingEntries[0];

                  firstEntry.vendorName_v3 += `, ${d?.vendorRequest?.name}`;
                  firstEntry.brandRequestIds.push(brandRequestIds);

                  existingEntries.push(entry);
                } else {
                  groupedByBrandName.set(brandName, [entry]);
                }
              });

              const shapedData = [];
              groupedByBrandName.forEach((group, key) => {
                const firstEntry = group[0];

                let combinedVendorNames = group
                  .map((item) => item.vendorName_v3)
                  .join(", ");
                firstEntry.vendorName_v3 = combinedVendorNames;

                shapedData.push(firstEntry);
              });

              return shapedData;
            }}
            refetch={refetch}
          />
        </>
      );
    }

    return <></>;
  };
  return (
    <Container>
      <TopBar>
        <h1>Reports</h1>
      </TopBar>
      <TabContainer>
        <Tabs>
          <Tab isActive={isDashboard} onClick={() => navigateTo(DASHBOARD)}>
            Dashboard
            <sup>{statusChanges?.length || 0}</sup>
          </Tab>
          <Tab
            isActive={isThirtyDaysNoChange}
            onClick={() => navigateTo(thirtyDaysNoChange)}
          >
            30-Day No Change
            <sup>{thirtyDaysNoChangeRecords?.length || 0}</sup>
          </Tab>
          <Tab
            isActive={isSixtyDaysNoChange}
            onClick={() => navigateTo(sixtyDaysNoChange)}
          >
            60-Day No Change
            <sup>{sixtyDaysNoChangeRecords?.length || 0}</sup>
          </Tab>
        </Tabs>
        <TabDisplay>{renderTable()}</TabDisplay>
      </TabContainer>
    </Container>
  );
};

export default Reports;
