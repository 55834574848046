/* eslint-disable react-hooks/exhaustive-deps */
import {
  CollapsibleHeader,
  getDateWithHumanReadableTime,
  RowFlex,
  Spinner,
  TableView,
  useAxios,
  useLoginContext,
  useUploadFile,
} from "common";
import { max } from "date-fns";
import { useEffect, useState } from "react";

const VendorBrands = ({ id }) => {
  const { isAdmin } = useLoginContext();
  const [show, setShow] = useState(false);
  const [brand, setBrand] = useState();

  const { callAxios } = useAxios();

  const adminProps = isAdmin;

  const { uploadFile } = useUploadFile();

  useEffect(() => {
    callAxios({
      method: "GET",
      url: `/brands/${id}`,
      params: { populate: "latestVendorIdUpload" },
    }).then((data) => setBrand(data.data));
  }, [id]);

  const setAsMainVendor = (id, brandId, reloadTable) => {
    brand?.defaultVendorId !== id &&
      callAxios({
        method: "PUT",
        url: `/brands/${brandId}`,
        data: { defaultVendorId: id },
      }).then((data) => {
        setBrand(data.data);
        reloadTable();
      });
  };

  const [pricesheet, setPricesheet] = useState([]);

  const fileUpload = async (e) => {
    const file = e.target.files?.[0];
    e.target.files = null;
    e.target.value = null;
    if (!file) return;
    uploadFile(file)
      .then(({ data: { filename } }) => {
        return filename;
      })
      .then((filename) => {
        callAxios({
          url: `/vendorBrands/${e.id}/brand/${id}/priceSheetDetails`,
          method: "PUT",
          data: {
            attachments: [filename],
          },
        }).then(e.reloadTable);
        return filename;
      })
      .then((filename) => {
        setPricesheet((prev) => [...prev, filename]);
      });
  };

  const fileDelete = ({ id: vbId, filename, reloadTable }) => {
    callAxios({
      url: `/vendorBrands/${vbId}/brand/${id}/priceSheetDetails`,
      method: "PUT",
      data: {
        attachments: [],
      },
    }).then(reloadTable);

    callAxios({
      method: "DELETE",
      url: `/files/${filename}`,
    });
  };

  if (!brand) return <Spinner />;

  const latestVendorUpload = brand.latestVendorIdUpload;

  const thisPricesheetDate = latestVendorUpload?.brandPriceSheets.find(
    (p) => p.brand === brand.id
  )?.attachmentDate;

  const dates = [thisPricesheetDate, brand.lastModifiedPricesheet]
    .filter(Boolean)
    .map((d) => new Date(d));
  const latestUploadDate = dates.length > 0 ? max(dates) : "";

  return (
    <div>
      <CollapsibleHeader header="Vendors" show={show} setShow={setShow} />
      {show && (
        <TableView
          defaultAction={() => {}}
          url="/vendorBrands"
          shapeData={(res) =>
            res.data.data.map((d) => {
              const sheet = d.brandPriceSheets?.find((s) => s.brand === id);
              const attachmentDate =
                sheet?.attachments?.length > 0
                  ? sheet.attachmentDate ?? ""
                  : "";
              return { ...d, attachmentDate };
            })
          }
          tableConfig={[
            {
              name: "name",
              header: "Name",
            },
            {
              name: "brandPriceSheets",
              header: "Files",
              isVendorPriceSheet: true,
              brandId: id,
              pricesheets: pricesheet,
              uploadFile: fileUpload,
              deleteFile: fileDelete,
            },
            { name: "attachmentDate", header: "Uploaded", isDate: true },
            {
              name: "code",
              header: "Code",
            },
            {
              header: "Main",
              defaultVendorId: brand?.defaultVendorId,
              isVendorPriceSheet: true,
              brandId: id,
              action: setAsMainVendor,
            },
          ]}
          header={
            <RowFlex>
              <div>Vendors</div>
              {latestVendorUpload && (
                <div style={{ fontSize: "0.8rem" }}>
                  (Latest: {latestVendorUpload.name} -{" "}
                  {getDateWithHumanReadableTime(latestUploadDate)})
                </div>
              )}
            </RowFlex>
          }
          pricesheet={pricesheet}
          {...adminProps}
        />
      )}
    </div>
  );
};

export default VendorBrands;
