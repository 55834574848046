import { formatNumber } from "common";
import styled from "styled-components";

const BanContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  gap: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpointS}) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const BanCard = styled.div`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.lightestGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.2rem;
  padding: 1rem;
  & h2 {
    font-size: 3rem;
  }
`;

const Ban = ({ kpi, prefix = "", suffix = "", title, subtitle }) => {
  const realKpi = typeof kpi === "number" ? formatNumber(kpi, true) : kpi;
  return (
    <BanCard>
      <h2>
        {prefix}
        {realKpi}
        {suffix}
      </h2>
      <h3>{title}</h3>
      {subtitle && <h4>{subtitle}</h4>}
    </BanCard>
  );
};

export { Ban, BanContainer };
