import ReactSlider from "react-slider";
import styled from "styled-components";

const PoDashContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
`;

const PoDashCharts = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
`;

const StyledSlider = styled(ReactSlider)`
  width: 250px;
  height: 25px;
`;

const StyledThumb = styled.div`
  height: 25px;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
`;

const Thumb = (props, state) => (
  <StyledThumb {...props}>{state.valueNow}</StyledThumb>
);

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${({ index, theme }) =>
    index === 2
      ? theme.colors.lightGrey
      : index === 1
      ? theme.colors.primary
      : theme.colors.lightGrey};
  border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

export { PoDashCharts, PoDashContainer, Track, Thumb, StyledSlider };
